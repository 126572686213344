import React from 'react';
import { Status, Wrapper } from '@googlemaps/react-wrapper';
import useSelector from '../../../../redux/typedHooks';

interface Props {
  render?: (status: Status) => React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  children: React.ReactNode;
}

export const GoogleMapsWrapper: React.FC<Props> = ({ render, children }) => {
  const { googleMapsApiKey } = useSelector((state) => state.application);
  return (
    <Wrapper render={render} apiKey={googleMapsApiKey}>
      {children}
    </Wrapper>
  );
};
